import { Typography } from '@amway/react-components';
import { mdiEmailOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Applications } from '../../../../@types/configuration';
import { Features } from '../../../../config/features';
import { WithFeaturesProxy } from '../../../../helpers/with-features-proxy';
import {
  ExecutionDetails,
  ProcessorLog,
  TransactionalEmail,
} from '../../../../resources/history-list/history-list-types';
import adminService from '../../../../service/admin.service';
import { getStatusBgColor, getStatusIconPath } from '../../../../utils/execution-utils';
import { makeQueryParams } from '../../../../utils/url-utils';
import IconButton from '../../../ui/icon-btn';
import JsonViewer from '../../../ui/json-viewer';
import ExecutionEmailPreviewModalComponent from '../email-preview-modal';
import './index.scss';

const formatExecutionDetailsInputJson = (details: ExecutionDetails) => {
  const firstLevelParse = JSON.parse(details.execution.input ?? '{}');

  if ('rawMessage' in firstLevelParse) {
    return { ...firstLevelParse, rawMessage: JSON.parse(firstLevelParse.rawMessage) };
  }

  return firstLevelParse;
};

const makeTestingToolSearchParams = (executionDetails: ExecutionDetails) => {
  return makeQueryParams({
    executionId: executionDetails.execution.executionId,
    sharedId: executionDetails.execution.sharedId,
    application: executionDetails.execution.application,
    processorName: executionDetails.execution.processorName,
    startDt: executionDetails.execution.startDt,
    endDt: executionDetails.execution.endDt,
  });
};

interface ExposedProps {
  details: ExecutionDetails;
  statusColor: string;
}

interface Props extends ExposedProps {
  canCompareEmails: boolean;
  hasEmailPreview: boolean;
  showUserId: boolean;
}

const ExecutionDetailsInfoComponent: React.FC<Props> = props => {
  const { details, statusColor, canCompareEmails, hasEmailPreview, showUserId } = props;
  const navigate = useNavigate();
  const target = useRef(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [transactionalEmail, setTransactionalEmail] = useState<TransactionalEmail>();
  const [transactionalEmailNA, setTransactionalEmailNA] = useState<TransactionalEmail>();
  const [transactionalEmailCOMMSHUB, setTransactionalEmailCOMMSHUB] = useState<TransactionalEmail>();
  const testingToolUrl = useMemo(() => `/testing-tool${makeTestingToolSearchParams(details)}`, [details]);

  const getTransactionalEmail = useCallback((execution?: ProcessorLog) => {
    if (execution) {
      return adminService.getTransactionalEmail(execution);
    }
  }, []);

  const togglePreviewEmailModal = useCallback(
    (transactionalEmail?: TransactionalEmail) => {
      if (!modalOpen) {
        if (transactionalEmail) {
          setTransactionalEmail(transactionalEmail);
        } else if (!details.execution.sharedId) {
          if (details.execution.application === Applications.NA) {
            setTransactionalEmail(transactionalEmailNA);
          } else if (details.execution.application === Applications.COMMSHUB) {
            setTransactionalEmail(transactionalEmailCOMMSHUB);
          }
        }
      }

      setModalOpen(!modalOpen);
    },
    [modalOpen, transactionalEmailNA, transactionalEmailCOMMSHUB, details],
  );

  const togglePreviewEmailModalNA = useCallback(() => {
    togglePreviewEmailModal(transactionalEmailNA);
  }, [togglePreviewEmailModal, transactionalEmailNA]);

  const togglePreviewEmailModalCOMMSHUB = useCallback(() => {
    togglePreviewEmailModal(transactionalEmailCOMMSHUB);
  }, [togglePreviewEmailModal, transactionalEmailCOMMSHUB]);

  const formatDate = (date: string, hour: string) => {
    const newDate = date.split('T')[0];
    return `${newDate}T${hour}.000`;
  };

  const compareEmailsUrl = useMemo<string | undefined>(() => {
    const otherExecutionId = details.otherExecution?.executionId;
    const { executionId, processorName, startDt, endDt } = details?.execution;

    if (processorName && executionId && otherExecutionId) {
      return `/compare-emails?processorName=${processorName}&firstExecutionId=${executionId}&secondExecutionId=${otherExecutionId}&startDt=${formatDate(
        String(startDt),
        '00:00',
      )}&endDt=${formatDate(String(endDt), '23:59')}`;
    }
  }, [details]);

  const compareEmails = useCallback(() => {
    if (compareEmailsUrl) {
      navigate(compareEmailsUrl);
    }
  }, [compareEmailsUrl, navigate]);

  const bgStatusColor = useMemo(() => getStatusBgColor(details.execution.status), [details]);

  const statusIconPath = useMemo(() => getStatusIconPath(details.execution.status), [details]);

  useEffect(() => {
    if (!transactionalEmailNA) {
      const execution = details.execution.application === Applications.NA ? details.execution : details.otherExecution;

      getTransactionalEmail(execution)?.then(setTransactionalEmailNA);
    }
  }, [getTransactionalEmail, transactionalEmailNA, details]);

  useEffect(() => {
    if (!transactionalEmailCOMMSHUB) {
      const execution =
        details.execution.application === Applications.COMMSHUB ? details.execution : details.otherExecution;

      getTransactionalEmail(execution)?.then(setTransactionalEmailCOMMSHUB);
    }
  }, [getTransactionalEmail, transactionalEmailCOMMSHUB, details]);

  return (
    <>
      <Row className="execution-details-info status">
        <Col
          className="d-flex align-items-center"
          style={{ borderLeftColor: statusColor, color: statusColor, backgroundColor: bgStatusColor }}>
          <Icon
            path={statusIconPath!}
            title="Status"
            size="16px"
            horizontal
            vertical
            rotate={180}
            color={statusColor}
          />
          {details.execution.status}
        </Col>
      </Row>
      {hasEmailPreview && (
        <Row className="execution-details-info mt-3">
          {details.execution.sharedId ? (
            <>
              {(details.execution.application === Applications.NA || details.otherExecution?.executionId) && (
                <Col className="d-flex justify-content-center">
                  <IconButton
                    mdiIconPath={mdiEmailOutline}
                    styleType="link"
                    onClick={togglePreviewEmailModalNA}
                    disabled={!details.hasEmailPreview || !transactionalEmailNA}
                    color="var(--primary)"
                    size="16px">
                    ACDP EMAIL PREVIEW
                  </IconButton>
                </Col>
              )}
              {(details.execution.application === Applications.COMMSHUB || details.otherExecution?.executionId) && (
                <Col className="d-flex justify-content-center">
                  <IconButton
                    mdiIconPath={mdiEmailOutline}
                    styleType="link"
                    onClick={togglePreviewEmailModalCOMMSHUB}
                    disabled={!details.hasEmailPreview || !transactionalEmailCOMMSHUB}
                    color="var(--primary)"
                    size="16px">
                    COMMS HUB EMAIL PREVIEW
                  </IconButton>
                </Col>
              )}
              {canCompareEmails && details.otherExecution?.executionId && (
                <Col className="d-flex justify-content-center">
                  <IconButton
                    mdiIconPath={mdiEmailOutline}
                    styleType="link"
                    onClick={compareEmails}
                    disabled={!details.hasEmailPreview}
                    color="var(--primary)"
                    size="16px"
                    href={compareEmailsUrl}>
                    COMPARE EMAILS
                  </IconButton>
                </Col>
              )}
            </>
          ) : (
            <Col className="d-flex justify-content-center">
              <IconButton
                mdiIconPath={mdiEmailOutline}
                styleType="link"
                onClick={togglePreviewEmailModal}
                disabled={!details.hasEmailPreview || !(transactionalEmailNA || transactionalEmailCOMMSHUB)}
                color="var(--primary)"
                size="16px">
                PREVIEW PROCESSED EMAIL
              </IconButton>
            </Col>
          )}
          <Col className="d-flex justify-content-center">
            <Link to={testingToolUrl} className="testing-tool-link">
              <Typography color="primary">OPEN ON TESTING TOOL</Typography>
              <Image src={'/assets/testing-tool-icon.svg'} className="ms-3" />
            </Link>
          </Col>
        </Row>
      )}
      <Row className="execution-details-info mb-5 mt-3">
        <Col md={4} className="side-informations">
          {details.execution.sharedId && (
            <>
              {details.execution.executionId && (
                <Typography color="text-gray">
                  Execution ID ({details.execution.application}):{' '}
                  <Typography weight="bold">{details.execution.executionId}</Typography>
                </Typography>
              )}
              {details.otherExecution?.executionId && (
                <Typography color="text-gray">
                  Execution ID ({details.otherExecution.application}):{' '}
                  <Typography weight="bold">{details.otherExecution?.executionId}</Typography>
                </Typography>
              )}
            </>
          )}
          <Typography color="text-gray">
            Message ID: <Typography weight="bold">{details.execution.messageId}</Typography>
          </Typography>
          <Typography color="text-gray">
            ABO Number: <Typography weight="bold">{details.execution.aboNumber}</Typography>
          </Typography>
          <Typography color="text-gray">
            Source: <Typography weight="bold">{details.execution.source}</Typography>
          </Typography>
          {showUserId && details.execution.userId && (
            <Typography color="text-gray">
              User ID: <Typography weight="bold">{details.execution.userId}</Typography>
            </Typography>
          )}
        </Col>
        <Col md={8} className="input">
          <Typography color="text-gray" className="d-flex mb-3">
            Input:
          </Typography>
          <Col className="input-output" ref={target}>
            <JsonViewer className="json-viewer" json={formatExecutionDetailsInputJson(details)} />
          </Col>
        </Col>
      </Row>
      <ExecutionEmailPreviewModalComponent
        show={modalOpen}
        onHide={togglePreviewEmailModal}
        transactionalEmail={transactionalEmail}
      />
    </>
  );
};

export default WithFeaturesProxy<ExposedProps>(
  Features.CompareEmail,
  Features.MktFeature_EmailPreview,
  Features.MktFeature_ShowUserIdOnExecutionDetails,
)((props, canCompare, emailPreview, showUserId) => {
  return (
    <ExecutionDetailsInfoComponent
      {...props}
      canCompareEmails={canCompare}
      hasEmailPreview={emailPreview}
      showUserId={showUserId}
    />
  );
});
