import { SystemHealth, Typography } from '@amway/react-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import ReportsSortedBarChartComponent from '../../components/charts/reports-sorted-bar-chart';
import TealiumDataLayer from '../../components/hocs/tealium-data-layer';
import IconButton from '../../components/ui/icon-btn';
import { Features } from '../../config/features';
import { WithFeaturesProxy } from '../../helpers/with-features-proxy';
import { ReportsSortedBarChartData } from '../../interface/reports';
import useMetrics from '../../resources/metrics/metrics-hook';
import useProcessors from '../../resources/processors/processors-hook';
import useSystemsHealth from '../../resources/systems-health/systems-health-hook';
import adminService from '../../service/admin.service';
import { subtractDays, toISOString } from '../../utils/date-utils';
import DashboardMetricsComponent from './dashboard-metrics';
import DashboardMetricsFormComponent from './dashboard-metrics-form';
import './index.scss';

interface ExposedProps {}

interface Props extends ExposedProps {
  hasAmwayProfile: boolean;
  hasExponea: boolean;
  hasOrderLookupAPI: boolean;
  hasProductInfoAPI: boolean;
  hasVolumeAPI: boolean;
  hasUplines: boolean;
  hasDocgen: boolean;
  hasPublisherAPI: boolean;
}

function DashboardComponent({
  hasAmwayProfile,
  hasExponea,
  hasOrderLookupAPI,
  hasProductInfoAPI,
  hasVolumeAPI,
  hasUplines,
  hasDocgen,
  hasPublisherAPI,
}: Props) {
  const { systemsHealth, fetchSystemsHealth } = useSystemsHealth();
  const { processors: processorsData, fetchProcessors } = useProcessors();
  const { metrics, fetchMetrics } = useMetrics();
  const [maxDate] = useState<string>(toISOString(new Date()));
  const [startDate, setStartDate] = useState<string>(toISOString(subtractDays(new Date(), 1)));
  const [endDate, setEndDate] = useState<string>(maxDate);
  const [lastUpdate, setLastUpdate] = useState<string>(new Date().toLocaleString());
  const [executionReports, setExecutionReports] = useState<ReportsSortedBarChartData[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const otherIndicatorsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchSystemsHealth();
  }, [fetchSystemsHealth]);

  const processors = useMemo(() => {
    return (processorsData.data ?? []).map(({ name }) => name);
  }, [processorsData.data]);

  const loadMetrics = useCallback(
    (e?) => {
      if (startDate && endDate) {
        e && e.preventDefault();
        setLoading(true);
        adminService
          .getExecutionReports({
            startDate: new Date(startDate + ' 00:00:00.000'),
            endDate: new Date(endDate + ' 23:59:59.999'),
            filters: { processor: processors },
            grouping: 'Processor',
          })
          .then(setExecutionReports)
          .finally(() => setLoading(false));

        fetchMetrics(startDate + ' 00:00:00.000', endDate + ' 23:59:59.999');
      }
    },
    [startDate, endDate, processors, fetchMetrics],
  );

  const loadIndicators = useCallback(() => {
    fetchSystemsHealth();
    setLastUpdate(new Date().toLocaleString());
  }, [fetchSystemsHealth]);

  useEffect(() => {
    if (!metrics.data) {
      fetchProcessors();
    }
  }, [metrics.data, fetchProcessors]);

  useEffect(() => {
    if (processors.length > 0) {
      loadMetrics();
    }
  }, [loadMetrics, processors.length]);

  useEffect(() => {
    loadIndicators();
  }, [loadIndicators]);

  const checkExistanceOtherIndicators = () => {
    if (otherIndicatorsContainerRef.current && otherIndicatorsContainerRef.current !== undefined) {
      return otherIndicatorsContainerRef.current?.children.length > 1;
    }
    return false;
  };

  return (
    <TealiumDataLayer
      page_name="Dashboard"
      page_section="metrics"
      page_category="Metrics"
      page_subCategory="System Health and Usage">
      <Container className="dashboard">
        <Card>
          <Card.Body>
            <Row className="title">
              <Col md={10}>
                <Typography variant="heading">System Health</Typography>
                <Typography weight="bold" color="text-gray">
                  Last update on {lastUpdate}
                </Typography>
              </Col>
              <Col md={2} className="d-flex justify-content-end">
                <IconButton onClick={loadIndicators} styleType="link">
                  REFRESH
                </IconButton>
              </Col>
            </Row>
            <Row className={`indicators ${checkExistanceOtherIndicators() && 'separator'}`}>
              <Col md={12}>
                <Typography variant="captionMedium" color="primary" weight="italic">
                  Self indicators
                </Typography>
              </Col>
              <Col md={4}>
                <SystemHealth name="API" status={systemsHealth.self.data} />
              </Col>
              {hasPublisherAPI && (
                <Col md={4}>
                  <SystemHealth name="Publisher API" status={systemsHealth.publisherApi.data} />
                </Col>
              )}
            </Row>
            <Row className="indicators" ref={otherIndicatorsContainerRef}>
              {checkExistanceOtherIndicators() && (
                <Col md={12}>
                  <Typography variant="captionMedium" color="primary" weight="italic">
                    Other indicators
                  </Typography>
                </Col>
              )}
              {hasAmwayProfile && (
                <Col md={4}>
                  <SystemHealth name="Amway Profile" status={systemsHealth.amwayProfile.data} />
                </Col>
              )}
              {hasExponea && (
                <Col md={4}>
                  <SystemHealth name="Exponea" status={systemsHealth.exponea.data} />
                </Col>
              )}
              {hasOrderLookupAPI && (
                <Col md={4}>
                  <SystemHealth name="Order Lookup API" status={systemsHealth.orderApi.data} />
                </Col>
              )}
              {hasProductInfoAPI && (
                <Col md={4}>
                  <SystemHealth name="Product Info API" status={systemsHealth.itemApi.data} />
                </Col>
              )}
              {hasVolumeAPI && (
                <Col md={4}>
                  <SystemHealth name="Volume API" status={systemsHealth.volumeApi.data} />
                </Col>
              )}
              {hasUplines && (
                <Col md={4}>
                  <SystemHealth name="Uplines" status={systemsHealth.uplines.data} />
                </Col>
              )}
              {hasDocgen && (
                <Col md={4}>
                  <SystemHealth name="Docgen" status={systemsHealth.docgen.data} />
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Row className="title">
              <Col>
                <Typography variant="heading">Data and Reports</Typography>
                <Typography weight="bold" color="text-gray" className="mt-3">
                  Core metrics from the ACDP system. Select the time periods below to update the date range input.
                </Typography>
              </Col>
            </Row>
            <DashboardMetricsFormComponent
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              maxDate={maxDate}
              submit={loadMetrics}
              loading={loading}
            />
            <DashboardMetricsComponent metrics={metrics.data} />
            {processors.length > 0 &&
              ((executionReports ?? [])?.length > 0 ? (
                <ReportsSortedBarChartComponent data={executionReports ?? []} id="executions-report-chart-dashboard" />
              ) : (
                <p>No metrics to display on chart.</p>
              ))}
          </Card.Body>
        </Card>
      </Container>
    </TealiumDataLayer>
  );
}

export default WithFeaturesProxy<ExposedProps>(
  Features.MktFeature_Health_AmwayProfile,
  Features.MktFeature_Health_Exponea,
  Features.MktFeature_Health_OrderLookupAPI,
  Features.MktFeature_Health_ProductInfoAPI,
  Features.MktFeature_Health_VolumeAPI,
  Features.MktFeature_Health_Uplines,
  Features.MktFeature_Health_Docgen,
  Features.MktFeature_Health_PublisherAPI,
)((props, ...hasFeatures) => {
  const [
    hasAmwayProfile,
    hasExponea,
    hasOrderLookupAPI,
    hasProductInfoAPI,
    hasVolumeAPI,
    hasUplines,
    hasDocgen,
    hasPublisherAPI,
  ] = hasFeatures;
  return (
    <DashboardComponent
      {...props}
      hasAmwayProfile={hasAmwayProfile}
      hasExponea={hasExponea}
      hasOrderLookupAPI={hasOrderLookupAPI}
      hasProductInfoAPI={hasProductInfoAPI}
      hasVolumeAPI={hasVolumeAPI}
      hasUplines={hasUplines}
      hasDocgen={hasDocgen}
      hasPublisherAPI={hasPublisherAPI}
    />
  );
});
