import { IconButton, Typography } from '@amway/react-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../@types/page';
import TealiumDataLayer from '../../components/hocs/tealium-data-layer';
import ExecutionHistoryDetailsCardComponent from '../../components/shared/execution/history-details-card';
import ExecutionHistoryFormComponent, { Filters } from '../../components/shared/execution/history-form';
import { isPROD } from '../../config/env';
import useQueryParams, { QueryParams } from '../../hooks/use-query-params';
import useExecutions from '../../resources/executions/executions-hook';
import { ExecutionStatus, ProcessorLog } from '../../resources/executions/executions-types';
import useHistoryList from '../../resources/history-list/history-list-hook';
import { addDays, subtractDays, toISOString } from '../../utils/date-utils';
import { makeQueryParams } from '../../utils/url-utils';
import ExecutionHistoryListComponent from './execution-history-list';
import './index.scss';

const makeExecutionDetailsNavigationSearchParams = (execution: ProcessorLog) => {
  return makeQueryParams({
    sharedId: execution.sharedId,
    executionId: execution.executionId,
    processorName: execution.processorName,
    application: execution.application,
    startDt: execution.startDt,
    endDt: execution.endDt,
  });
};

const daysAgo = 2;
const daysLater = 1;

const nonEmptyArrayOrUndefined = (entry?: string[] | string): string[] | undefined => {
  if (typeof entry === 'string') return [entry];
  if (Array.isArray(entry) && entry.length > 0) return entry;
};

const queryParamsToState = (
  filterParams: QueryParams,
): {
  filters: Filters;
  pagination: {
    offset: number;
    limit: number;
  };
} => {
  if (Object.keys(filterParams).length === 0) {
    return {
      filters: {
        startDate: toISOString(subtractDays(new Date(), daysAgo)),
        endDate: toISOString(addDays(new Date(), daysLater)),
        reprocessedEvents: true,
        searchText: '',
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
    };
  }

  return {
    filters: {
      startDate: (filterParams.startDate as string) ?? toISOString(subtractDays(new Date(), daysAgo)),
      endDate: (filterParams.endDate as string) ?? toISOString(addDays(new Date(), daysLater)),
      reprocessedEvents: filterParams.reprocessedEvents ? filterParams.reprocessedEvents === 'true' : true,
      searchText: (filterParams.searchText as string) ?? '',
      userId: filterParams.userId as string,
      processor: nonEmptyArrayOrUndefined(filterParams.processor),
      country: nonEmptyArrayOrUndefined(filterParams.country),
      status: nonEmptyArrayOrUndefined(filterParams.status) as ExecutionStatus[],
    },
    pagination: {
      limit: filterParams.limit ? parseInt(filterParams.limit as string) : 10,
      offset: filterParams.offset ? parseInt(filterParams.offset as string) : 0,
    },
  };
};

const stateToQueryParams = (state: {
  filters: Filters;
  pagination: {
    offset: number;
    limit: number;
  };
}): QueryParams => {
  return {
    startDate: state.filters.startDate,
    endDate: state.filters.endDate,
    processor: state.filters.processor ?? [],
    country: state.filters.country ?? [],
    userId: state.filters.userId ? state.filters.userId : [],
    reprocessedEvents: state.filters.reprocessedEvents ? 'true' : 'false',
    searchText: state.filters.searchText,
    status: state.filters.status ?? [],
    limit: state.pagination.limit.toString(),
    offset: state.pagination.offset.toString(),
  };
};

export default function ExecutionHistoryComponent() {
  const { executions, fetchExecutions } = useExecutions();
  const navigate = useNavigate();
  const { selectedExecution, details, handleBackClick, handleRetry, handleBatchRetry, togglePreviewEmailModal } =
    useHistoryList();
  const [filterParams, setFilterParams] = useQueryParams();
  const state = useMemo(() => queryParamsToState(filterParams), [filterParams]);
  const [filters, setFilters] = useState<Filters>(state.filters);

  useEffect(() => {
    setFilters(state.filters);
  }, [state.filters]);

  const fetchNewExecutions = useCallback(() => {
    void fetchExecutions({
      ...state.filters,
      startDate: state.filters.startDate ? state.filters.startDate + 'T00:00:00.000' : undefined,
      endDate: state.filters.endDate ? state.filters.endDate + 'T23:59:59.999' : undefined,
      page: {
        limit: state.pagination.limit,
        offset: state.pagination.offset,
      },
    });
  }, [fetchExecutions, state]);

  useEffect(() => {
    fetchNewExecutions();
  }, [fetchNewExecutions]);

  const handleSubmit = useCallback(() => {
    setFilterParams(
      stateToQueryParams({
        filters: filters,
        pagination: {
          limit: state.pagination.limit,
          // if any filters are change the page should go back to the first one to display results
          offset: JSON.stringify(state.filters) !== JSON.stringify(filters) ? 0 : state.pagination.offset,
        },
      }),
    );
  }, [filters, setFilterParams, state]);

  const loadPage = useCallback(
    (page: Page) => {
      return setFilterParams(
        stateToQueryParams({
          filters: filters,
          pagination: page,
        }),
      );
    },
    [setFilterParams, filters],
  );

  const handlePageChanges = useCallback(
    (page: number) => {
      setFilterParams(
        stateToQueryParams({
          filters: filters,
          pagination: { limit: state.pagination.limit, offset: page * state.pagination.limit },
        }),
      );
    },
    [filters, setFilterParams, state.pagination.limit],
  );

  const makeNavToDetailsLink = useCallback((execution: ProcessorLog): string => {
    return '/execution-history/details' + makeExecutionDetailsNavigationSearchParams(execution);
  }, []);

  const handleDetailsClick = (execution: ProcessorLog) => {
    navigate({
      pathname: `/execution-history/details`,
      search: makeExecutionDetailsNavigationSearchParams(execution),
    });
  };

  const handleFiltersChange = useCallback((newFilters: Filters) => {
    setFilters(newFilters);
  }, []);

  return (
    <TealiumDataLayer
      page_name="Execution History"
      page_section="execution-hist"
      page_category="Historic Data"
      page_subCategory="Execution Historic Data">
      <Container className="execution-history">
        <Card style={{ display: selectedExecution ? 'none' : 'flex' }}>
          <Card.Body>
            <Row className="title">
              <Col>
                <Typography variant="heading">Execution History</Typography>
                <Typography weight="bold" color="text-gray" className="mt-3">
                  Search, view email preview and reprocess the executions on the list below.
                </Typography>
              </Col>
              <Col>
                <Col md={2} className="ms-auto">
                  <IconButton onClick={fetchNewExecutions} styleType="link">
                    REFRESH
                  </IconButton>
                </Col>
              </Col>
            </Row>
            <ExecutionHistoryFormComponent
              onSubmit={handleSubmit}
              filters={filters}
              onFiltersChange={handleFiltersChange}
              submitOnLoad
              daysRange={isPROD ? undefined : 100}
            />
            <ExecutionHistoryListComponent
              response={executions.loading ? null : executions.data}
              loadPage={loadPage as any}
              limit={state.pagination.limit}
              timedout={executions.error?.statusCode === 408}
              makeNavLink={makeNavToDetailsLink}
              error={!!executions.error && executions.error?.statusCode !== 408}
              selectedPage={Math.round(state.pagination.offset / state.pagination.limit)}
              setSelectedPage={handlePageChanges}
              onDetailsClick={handleDetailsClick}
              onEmailPreviewClick={togglePreviewEmailModal}
              onRetryClick={handleRetry}
              onBatchRetryClick={handleBatchRetry}
            />
            {!executions.loading && executions.data?.db && (
              <Row className="mt-3">
                <Col>
                  <Card.Subtitle>
                    Request done on DB: <b>{executions.data?.db}</b>
                  </Card.Subtitle>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
        {selectedExecution && (
          <ExecutionHistoryDetailsCardComponent
            execution={selectedExecution}
            details={details}
            onBackClick={handleBackClick}
          />
        )}
      </Container>
    </TealiumDataLayer>
  );
}
