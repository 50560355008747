import { Col, Image, Row } from 'react-bootstrap';
import { Markets } from '../../../../@types/configuration';
import environment from '../../../../config/env';

type IconVariant = 'search-error' | 'search-time-out' | 'no-exceptions-found' | 'no-results-found';

export interface Props {
  iconVariant?: IconVariant;
  header?: string;
  description?: string;
}

const iconVariantMap: Record<IconVariant, Record<Markets, string>> = {
  'no-exceptions-found': {
    INDIA: 'no-exceptions-found-icon-india.svg',
    NA: 'no-exceptions-found-icon-na.svg',
    THAILAND: 'no-exceptions-found-icon-thailand.svg',
  },
  'no-results-found': {
    INDIA: 'no-results-found-icon-india.svg',
    NA: 'no-results-found-icon-na.svg',
    THAILAND: 'no-results-found-icon-thailand.svg',
  },
  'search-error': {
    INDIA: 'search-error-icon-india.svg',
    NA: 'search-error-icon-na.svg',
    THAILAND: 'search-error-icon-thailand.svg',
  },
  'search-time-out': {
    INDIA: 'search-timedout-icon-india.svg',
    NA: 'search-timedout-icon-na.svg',
    THAILAND: 'search-timedout-icon-thailand.svg',
  },
};

export default function TableWarning({ header, description, iconVariant }: Props) {
  return (
    <Row className="no-results-found">
      <Col className="d-flex flex-column align-items-center">
        <Image src={`/assets/${iconVariantMap[iconVariant!][environment.MARKET]}`} width="121.56" height="119.36" />
        <h1>{header}</h1>
        <h4>{description}</h4>
      </Col>
    </Row>
  );
}
