import { Markets } from '../@types/configuration';

interface ColorSchema {
  primary: string;
  secondary: string;
  tertiary: string;
}

type ColorSchemaMap = Record<Markets, ColorSchema>;

const colorSchemaMap: ColorSchemaMap = {
  [Markets.NA]: {
    primary: '#194264',
    secondary: '#cce1ff',
    tertiary: '#f5f9ff',
  },
  [Markets.INDIA]: {
    primary: '#2B6747',
    secondary: '#c9dfd3',
    tertiary: '#f0f8f4',
  },
  [Markets.THAILAND]: {
    primary: '#196464',
    secondary: '#C8EEEE',
    tertiary: '#F0FBFB',
  },
};

export default colorSchemaMap;
