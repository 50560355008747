import { Typography } from '@amway/react-components';
import { mdiKeyboardBackspace } from '@mdi/js';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { ExecutionDetails, ProcessorLog } from '../../../../resources/history-list/history-list-types';
import { toLocaleString } from '../../../../utils/date-utils';
import { getStatusColor } from '../../../../utils/execution-utils';
import TealiumDataLayer from '../../../hocs/tealium-data-layer';
import IconButton from '../../../ui/icon-btn';
import ExecutionHistoryDetailsComponent from '../history-details';
import './index.scss';

type Props = {
  execution: ProcessorLog;
  details?: ExecutionDetails | null;
  onBackClick?: VoidFunction;
};

function ExecutionHistoryDetailsCardComponent(
  { execution, details, onBackClick }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const statusColor = useMemo(
    () => getStatusColor(execution.status ?? details?.execution.status),
    [execution, details],
  );

  const startDate = useMemo(() => {
    const startDt = execution.startDt ?? details?.execution.startDt;

    return startDt ? toLocaleString(startDt) : '';
  }, [execution, details]);

  const endDate = useMemo(() => {
    const endDt = execution.endDt ?? details?.execution.endDt;

    return endDt ? toLocaleString(endDt) : '';
  }, [execution, details]);

  const cardTitle = useMemo(() => (execution.sharedId ? 'Shared ID: ' : 'Execution ID: '), [execution]);

  return (
    <TealiumDataLayer
      page_name="Execution Details "
      page_section="execution-details"
      page_category="Audition"
      page_subCategory="Exception Historic Data">
      <Card className="execution-history-details" ref={ref}>
        <Card.Body>
          {onBackClick && (
            <Row>
              <Col>
                <IconButton mdiIconPath={mdiKeyboardBackspace} onClick={onBackClick} title="Back" size="27px">
                  Execution History
                </IconButton>
              </Col>
            </Row>
          )}
          <Row className="mb-5 mt-4">
            <Col>
              <Typography variant="heading">
                {cardTitle}
                <span className="execution-id" style={{ color: statusColor }}>
                  {execution.sharedId ?? execution.executionId}
                </span>
              </Typography>
              <Typography weight="bold" color="text-gray" className="processor mt-3">
                <Row>
                  <Col>Processor: {execution.processorName}</Col>
                  <Col>Start date: {startDate} (GMT)</Col>
                  <Col>End date: {endDate} (GMT)</Col>
                </Row>
              </Typography>
            </Col>
          </Row>
          <ExecutionHistoryDetailsComponent execution={execution} statusColor={statusColor} details={details} />
        </Card.Body>
      </Card>
    </TealiumDataLayer>
  );
}

export default forwardRef(ExecutionHistoryDetailsCardComponent);
