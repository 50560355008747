import { Checkbox, SxProps, TableHead, Theme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { Page } from '../../../@types/page';
import IconButton from '../icon-btn';
import CollapsibleTableRow from './collapsible-table-row';
import './index.scss';
import TableCellOverlayComponent from './table-cell-overlay';
import TablePaginationActions from './table-pagination-actions';
import TableWarning, { Props as TableWarningProps } from './table-warning';

export interface Column {
  id: string;
  alternativeId?: string;
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'right';
  href?: string;
  sx?: SxProps<Theme>;
  format?: (value: any) => any;
  getSx?: (row: any) => SxProps<Theme>;
  getIcon?: (row: any) => JSX.Element | ReactNode | undefined;
  // this is intended to return the navigation url
  // onNavClick?: (row: Row) => string;
}

export interface SelectedRowsDict {
  [id: string]: any;
}

type Props = {
  columns: Column[];
  rows?: any[] | null;
  rowIdPropName: string;
  selectedPage: number;
  setSelectedPage: (page: number) => void;
  selectedRowsPerPage?: number;
  searchable?: boolean;
  collapsible?: boolean;
  collapsibleRowsPropName?: string;
  selectable?: boolean;
  selected?: SelectedRowsDict;
  setSelected?: (selected: SelectedRowsDict) => void;
  selectedDisplayMessage?: string;
  selectedActionBtnOnClick?: VoidFunction;
  selectedActionBtnTxt?: string;
  sx?: SxProps<Theme>;
  totalCount?: number;
  loadPage?: (page: Page) => void;
  timedout?: boolean;
  setTimedout?: (timedout: boolean) => void;
  error?: boolean;
  setError?: (error: boolean) => void;
  noResultsIconVariant?: TableWarningProps['iconVariant'];
  noResultsHeader?: string;
  noResultsDescription?: string;
  onClick?: (column: Column, row: any) => void;
  showAllOption?: boolean;
};

export default function PaginatedTableComponent({
  columns = [],
  rows,
  rowIdPropName,
  selectedPage,
  setSelectedPage,
  selectedRowsPerPage = 10,
  searchable = false,
  collapsible = false,
  collapsibleRowsPropName,
  selectable = false,
  selected = {},
  setSelected = () => {},
  selectedDisplayMessage = 'row(s) selected in this page',
  selectedActionBtnOnClick = () => {},
  selectedActionBtnTxt = 'Trigger Action',
  sx,
  totalCount,
  loadPage,
  timedout = false,
  setTimedout = () => {},
  error = false,
  setError = () => {},
  noResultsIconVariant = 'no-results-found',
  noResultsHeader,
  noResultsDescription,
  onClick,
  showAllOption = true,
}: Props) {
  const [rowsPerPage, setRowsPerPage] = useState<number>(selectedRowsPerPage);
  const [filteredRows, setFilteredRows] = useState<any[] | null | undefined>(rows);

  const displayCollapsibleRow = useMemo(
    () => collapsible && collapsibleRowsPropName,
    [collapsible, collapsibleRowsPropName],
  );

  useEffect(() => {
    setRowsPerPage(selectedRowsPerPage);
  }, [selectedRowsPerPage]);

  const getPageResults = useCallback(
    (page: Page) => {
      setTimedout(false);
      setError(false);
      loadPage?.(page);
    },
    [loadPage, setTimedout, setError],
  );

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setSelectedPage(newPage);

      const page: Page = {
        limit: rowsPerPage,
        offset: newPage * rowsPerPage,
      };

      getPageResults(page);
    },
    [rowsPerPage, getPageResults, setSelectedPage],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const rowsPerPage = parseInt(event.target.value, 10);

      setRowsPerPage(rowsPerPage);
      setSelectedPage(0);

      const page: Page = {
        limit: rowsPerPage,
        offset: 0,
      };

      getPageResults(page);
    },
    [getPageResults, setSelectedPage],
  );

  const formatCell = useCallback((column: Column, row: any) => {
    const value = row[column.id] ? row[column.id] : column.alternativeId ? row[column.alternativeId] : '';
    return column.format ? column.format(value) : value;
  }, []);

  const filter = useCallback(
    (searchText: string = '') => {
      if (searchText) {
        setSelectedPage(0);
        setFilteredRows(
          rows?.filter(row => Object.values(row).some((value: any) => value?.toString().includes(searchText))),
        );
      } else {
        setFilteredRows(rows);
      }
    },
    [rows, setSelectedPage],
  );

  const isRowSelected = useCallback(
    (row: any, id: string = row[rowIdPropName]) => id in selected,
    [rowIdPropName, selected],
  );

  const selectRow = useCallback(
    (e: ChangeEvent<HTMLInputElement>, row: any) => {
      e.stopPropagation();

      const id = row[rowIdPropName];

      if (isRowSelected(row, id)) {
        delete selected[id];
      } else {
        selected[id] = row;
      }

      setSelected({ ...selected });
    },
    [rowIdPropName, selected, setSelected, isRowSelected],
  );

  const rowsSelected = useMemo<number>(() => Object.keys(selected).length, [selected]);

  const isAllRowsSelected = useMemo<boolean>(
    () => rowsSelected === (filteredRows?.length ?? 0),
    [rowsSelected, filteredRows],
  );

  const selectAllRows = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();

      if (isAllRowsSelected) {
        setSelected({});
      } else {
        const selectedRows = filteredRows?.reduce((prev, curr) => {
          const id = curr[rowIdPropName];
          prev[id] = curr;

          return prev;
        }, {} as SelectedRowsDict);

        setSelected(selectedRows);
      }
    },
    [rowIdPropName, setSelected, filteredRows, isAllRowsSelected],
  );

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  useEffect(() => {
    setSelected({});
  }, [setSelected, filteredRows]);

  if (timedout) {
    return (
      <TableWarning
        iconVariant="search-time-out"
        header="This search can't be reached"
        description="Search Results took too long to respond. Try again in a few moments."
      />
    );
  } else if (error) {
    return (
      <TableWarning
        iconVariant="search-error"
        header="This search returned an error"
        description="An unexpected error occurred during this search. Try again!"
      />
    );
  } else if (filteredRows === undefined) {
    return <></>;
  } else if (filteredRows === null) {
    return (
      <Row className="loader">
        <Col className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="success" />
        </Col>
      </Row>
    );
  } else if (filteredRows.length === 0) {
    return (
      <TableWarning iconVariant={noResultsIconVariant} header={noResultsHeader} description={noResultsDescription} />
    );
  }

  return (
    <Paper id="paginated-table" className="paginated-table" sx={{ boxShadow: 'none' }}>
      <TableContainer sx={sx}>
        {searchable && (
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Control placeholder="Search..." onChange={e => filter(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
        <Table stickyHeader>
          <TableHead>
            {rowsSelected > 0 && (
              <TableRow className="rows-selected">
                <TableCell colSpan={Math.floor(columns.length / 2) + 1}>
                  {`${rowsSelected} of ${totalCount} ${selectedDisplayMessage}`}
                </TableCell>
                <TableCell colSpan={Math.ceil(columns.length / 2)}>
                  <div className="d-flex flex-row-reverse">
                    <IconButton styleType="link" onClick={selectedActionBtnOnClick} color="var(--primary)" size="16px">
                      <strong>{selectedActionBtnTxt}</strong>
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              {selectable && (
                <TableCell padding="checkbox">
                  <Checkbox
                    sx={{
                      color: 'var(--primary)',
                      '&.Mui-checked': {
                        color: 'var(--primary)',
                      },
                    }}
                    checked={isAllRowsSelected}
                    onChange={selectAllRows}
                  />
                </TableCell>
              )}
              {displayCollapsibleRow && <TableCell />}
              {columns.map(column => (
                <TableCell key={column.id} align={column.align}>
                  <strong>{column.label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(!loadPage && rowsPerPage > 0
              ? filteredRows.slice(selectedPage * rowsPerPage, selectedPage * rowsPerPage + rowsPerPage)
              : filteredRows
            ).map(row =>
              displayCollapsibleRow ? (
                <CollapsibleTableRow
                  key={row[rowIdPropName]}
                  columns={columns}
                  row={row}
                  formatCell={formatCell}
                  collapsibleRowIdPropName={rowIdPropName}
                  collapsibleRowsPropName={collapsibleRowsPropName!}
                  collapsibleRows={row[collapsibleRowsPropName!]}
                  onClick={onClick}
                />
              ) : (
                <TableRow hover role="checkbox" tabIndex={-1} key={row[rowIdPropName]} selected={isRowSelected(row)}>
                  {selectable && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        sx={{
                          color: 'var(--primary)',
                          '&.Mui-checked': {
                            color: 'var(--primary)',
                          },
                        }}
                        checked={isRowSelected(row)}
                        onChange={e => selectRow(e, row)}
                      />
                    </TableCell>
                  )}
                  {columns.map(column => (
                    <TableCellOverlayComponent
                      key={column.id}
                      column={column}
                      row={row}
                      formatCell={formatCell}
                      onClick={onClick}
                    />
                  ))}
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 50, 100].concat(
          showAllOption ? ({ value: 999999999, label: 'All' } as any) : [],
        )}
        colSpan={displayCollapsibleRow ? columns.length + 1 : columns.length}
        count={totalCount ?? filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={selectedPage}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        sx={{ overflow: 'hidden' }}
      />
    </Paper>
  );
}
