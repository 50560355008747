import { mdiAutorenew, mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import { useCallback, useMemo, useState } from 'react';
import { Page } from '../../../@types/page';
import IconButton from '../../../components/ui/icon-btn';
import PaginatedTableComponent, { Column, SelectedRowsDict } from '../../../components/ui/paginated-table';
import { Features } from '../../../config/features';
import { WithFeaturesProxy } from '../../../helpers/with-features-proxy';
import useExecutions from '../../../resources/executions/executions-hook';
import { ExecutionStatus, ProcessorLog } from '../../../resources/history-list/history-list-types';
import { getStatusColor, getStatusIconPath, shortenUUID } from '../../../utils/execution-utils';

interface ExposedProps {
  selectedPage: number;
  setSelectedPage: (page: number) => void;
  timedout: boolean;
  setTimedout: (timedout: boolean) => void;
  error: boolean;
  setError: (error: boolean) => void;
  loadPage: (page: Page) => void;
  onDetailsClick: (execution: ProcessorLog) => void;
  onRetryClick: (execution: ProcessorLog) => void;
  onBatchRetryClick: (execution: ProcessorLog[]) => void;
}

interface Props extends ExposedProps {
  canSeeDetails: boolean;
  canReprocess: boolean;
  showUserId: boolean;
  mktHasResendEmail: boolean;
}

const ExceptionHistoryListComponent: React.FC<Props> = props => {
  const {
    selectedPage,
    setSelectedPage,
    timedout = false,
    setTimedout,
    error = false,
    setError,
    loadPage,
    onDetailsClick,
    onRetryClick,
    onBatchRetryClick,
    canSeeDetails,
    canReprocess,
    showUserId,
    mktHasResendEmail,
  } = props;
  const [selected, setSelected] = useState<SelectedRowsDict>({});
  const [reprocessLabel] = useState<string>(mktHasResendEmail ? 'Resend' : 'Reprocess');

  const { exceptions } = useExecutions();

  const [columns] = useState<Column[]>(
    (
      [
        {
          id: 'status',
          label: 'Status',
          getIcon: (row: any) => {
            const status: ExecutionStatus | undefined = row.status;

            if (status) {
              const statusIconPath = getStatusIconPath(status);
              const statusColor = getStatusColor(status);

              return (
                <Icon
                  path={statusIconPath!}
                  title="Status"
                  size="16px"
                  horizontal
                  vertical
                  rotate={180}
                  color={statusColor}
                  style={{ verticalAlign: 'text-bottom', marginRight: '8px' }}
                />
              );
            }
          },
        },
        {
          id: 'executionId',
          label: 'Execution ID',
          format: (value: string) => shortenUUID(value),
        },
        {
          id: 'aboNumber',
          label: 'ABO Number',
        },
        showUserId ? { id: 'userId', label: 'User ID' } : null,
        {
          id: 'processorName',
          label: 'Processor',
        },
        {
          id: 'endDt',
          alternativeId: 'startDt',
          label: 'Date (GMT)',
          format: (value: string) => new Date(value).toLocaleString(),
        },
        canReprocess
          ? {
              id: mktHasResendEmail ? 'resend' : 'reprocess',
              maxWidth: 1.5,
              getIcon: (row: ProcessorLog) => (
                <IconButton
                  mdiIconPath={mdiAutorenew}
                  onClick={() => onRetryClick(row)}
                  disabled={row.resending}
                  spin={row.resending}
                  color={row.resending ? 'var(--detail-gray)' : 'var(--primary)'}
                  title={`${reprocessLabel} email`}
                  size="18px"
                />
              ),
            }
          : null,
        canSeeDetails
          ? {
              id: 'details',
              maxWidth: 1.5,
              getIcon: (row: ProcessorLog) => (
                <IconButton
                  mdiIconPath={mdiOpenInNew}
                  onClick={() => onDetailsClick(row)}
                  color="var(--primary)"
                  title="Execution details"
                  size="18px"
                />
              ),
            }
          : null,
      ] as Column[]
    ).filter(column => column),
  );

  const handleBatchRetryClick = useCallback(() => {
    const executions = Object.values(selected);
    onBatchRetryClick(executions);
  }, [onBatchRetryClick, selected]);

  const rows = useMemo(() => {
    if (exceptions.data?.executions === undefined) {
      return undefined;
    } else if (exceptions.loading) {
      return null;
    }

    return exceptions.data?.executions;
  }, [exceptions]);

  return (
    <PaginatedTableComponent
      columns={columns}
      rows={rows}
      rowIdPropName="executionId"
      selectedPage={selectedPage}
      setSelectedPage={setSelectedPage}
      selectable={true}
      selected={selected}
      setSelected={setSelected}
      selectedDisplayMessage="execution(s) selected in this page"
      selectedActionBtnOnClick={handleBatchRetryClick}
      selectedActionBtnTxt={`${reprocessLabel} Execution(s)`}
      totalCount={exceptions.data?.total ?? rows?.length ?? Number.MAX_SAFE_INTEGER}
      loadPage={loadPage}
      timedout={timedout}
      setTimedout={setTimedout}
      error={error}
      setError={setError}
      noResultsIconVariant="no-exceptions-found"
      noResultsHeader="No Exceptions Found"
      noResultsDescription="Input fields in filter hasn't returned exceptions"
      sx={{ maxWidth: 'calc(100vw - 104px - 2*24px - 32px - 38px - 19px)' }}
      showAllOption={false}
    />
  );
};

export default WithFeaturesProxy<ExposedProps>(
  Features.ReprocessEmail,
  Features.ExceptionHistoryScreen_Details,
  Features.MktFeature_ShowUserIdOnExecutionDetails,
  Features.MktFeature_ResendEmail,
)((props, ...hasFeatures) => {
  const [hasReprocessFeature, hasDetailsFeature, showUserId, mktHasResendEmail] = hasFeatures;

  return (
    <ExceptionHistoryListComponent
      {...props}
      canSeeDetails={hasDetailsFeature}
      canReprocess={hasReprocessFeature}
      showUserId={showUserId}
      mktHasResendEmail={mktHasResendEmail}
    />
  );
});
