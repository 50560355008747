import { Button } from '@amway/react-components';
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import DateRangeFieldsComponent from '../../../components/ui/date-range-fields';
import DropdownBtn, { Item } from '../../../components/ui/dropdown-btn';
import MultiSelectDropdownBtn from '../../../components/ui/multi-select-dropdown-btn';
import { Features } from '../../../config/features';
import { WithFeaturesProxy } from '../../../helpers/with-features-proxy';
import { GenerateReportsRequest } from '../../../interface/reports';
import { ExecutionStatus } from '../../../resources/history-list/history-list-types';
import useMetrics from '../../../resources/metrics/metrics-hook';
import useProcessors from '../../../resources/processors/processors-hook';
import { addDays, subtractDays, toISOString } from '../../../utils/date-utils';
import './index.scss';

const DEFAULT_DAYS_RANGE = 3;
const DEFAULT_DAYS_AGO = 2;
const DEFAULT_DAYS_LATER = 1;
const DEFAULT_INTERVAL_REF = 60000;

const reasonCodes = [
  { id: 'LateRenewal', label: 'Late Renewal' },
  { id: 'Resolve MissingInfo', label: 'Resolve MissingInfo' },
  { id: 'Promotional Renewal', label: 'Promotional Renewal' },
  { id: 'RECLS', label: 'RECLS' },
  { id: 'SelfResign', label: 'Self Resign' },
  { id: 'BUSINESS NATURE', label: 'BUSINESS NATURE' },
  { id: 'RenewalNoCharge', label: 'Renewal No Charge' },
];

interface ExposedProps {
  onSubmit: (request: GenerateReportsRequest, cBRFilter: boolean) => void;
  loading: boolean;
  availableStatus?: ExecutionStatus[];
  daysAgo?: number;
  daysLater?: number;
  daysRange?: number;
}

interface Props extends ExposedProps {
  mktHasMultipleCountry: boolean;
}

function ReportsFormComponent({
  onSubmit,
  loading,
  availableStatus = Object.values(ExecutionStatus),
  daysRange = DEFAULT_DAYS_RANGE,
  daysAgo = DEFAULT_DAYS_AGO,
  daysLater = DEFAULT_DAYS_LATER,
  mktHasMultipleCountry,
}: Props) {
  const { allCountries, fetchAllCountries } = useMetrics();
  const { processors: processorsData, fetchProcessors } = useProcessors();
  const [maxDate, setMaxDate] = useState<string>(toISOString(addDays(new Date(), daysLater)));
  const processors: Item[] = useMemo(
    () => (processorsData.data ?? []).map(({ name }) => ({ label: name, id: name })),
    [processorsData.data],
  );
  const [status] = useState<Item[]>(availableStatus.map(statusName => ({ id: statusName, label: statusName })));
  const [cBRFilter, setCBRFilter] = useState<boolean>(false);

  const [exceedsDaysRange, setExceedsDaysRange] = useState<boolean>(false);
  const intervalRef = useRef<NodeJS.Timeout>();

  const filterGroupByOptions = useMemo(() => {
    const defaultValues = [
      { id: 'Processor', label: 'Processor' },
      { id: 'Status', label: 'Status' },
      { id: 'CBR', label: 'CBR' },
    ];
    if (mktHasMultipleCountry) defaultValues.push({ id: 'Country', label: 'Country' });
    return defaultValues;
  }, [mktHasMultipleCountry]);

  useEffect(() => {
    fetchAllCountries();
    fetchProcessors();
  }, [fetchAllCountries, fetchProcessors]);

  const countryOptions = useMemo(() => {
    if (allCountries.data && allCountries.data !== undefined) {
      return allCountries.data.filter(country => country !== null).map(country => ({ id: country, label: country }));
    }
    return [];
  }, [allCountries.data]);

  const [filters, setFilters] = useState<GenerateReportsRequest>({
    grouping: 'Processor' as GenerateReportsRequest['grouping'],
    startDate: subtractDays(new Date(), daysAgo),
    endDate: addDays(new Date(), daysLater),
    filters: {
      country: [],
      processor: [],
      status: [],
      reasonCode: [],
    },
  });

  const submit = useCallback(() => {
    onSubmit(filters, cBRFilter);
  }, [filters, onSubmit, cBRFilter]);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      submit();
    },
    [submit],
  );

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setMaxDate(toISOString(addDays(new Date(), daysLater)));
    }, DEFAULT_INTERVAL_REF);

    return () => {
      clearInterval(intervalRef.current!);
    };
  }, [daysLater]);

  const handleSelectGrouping = (grouping: string) => {
    if (grouping !== 'CBR') {
      setFilters(prev => ({ ...prev, grouping: grouping as GenerateReportsRequest['grouping'] }));
      setCBRFilter(false);
    } else {
      setCBRFilter(true);
    }
  };

  const handleMultiSelectItemsClick = (filter: string, items?: Item[]) => {
    setFilters(prev => {
      switch (filter) {
        case 'Country':
          return {
            ...prev,
            filters: {
              ...prev.filters,
              country:
                countryOptions.length === items?.length || items?.length === 0
                  ? undefined
                  : items?.map(item => String(item.id)),
            },
          };
        case 'Processor':
          return {
            ...prev,
            filters: {
              ...prev.filters,
              processor:
                processors.length === items?.length || items?.length === 0
                  ? undefined
                  : items?.map(item => String(item.id)),
            },
          };
        case 'Status':
          return {
            ...prev,
            filters: {
              ...prev.filters,
              status:
                status.length === items?.length || items?.length === 0
                  ? undefined
                  : items?.map(item => String(item.id)),
            },
          };
        case 'Reason':
          return {
            ...prev,
            filters: {
              ...prev.filters,
              reasonCode:
                reasonCodes.length === items?.length || items?.length === 0
                  ? undefined
                  : items?.map(item => String(item.id)),
            },
          };
        default:
          return prev;
      }
    });
  };

  return (
    <Form className="reports-form" onSubmit={handleSubmit}>
      <Row>
        <DateRangeFieldsComponent
          lg={3}
          xl={3}
          xxl={3}
          styleType="filter"
          startDate={toISOString(filters.startDate)}
          setStartDate={date => setFilters(prev => ({ ...prev, startDate: new Date(date + 'T00:00:00.000') }))}
          endDate={toISOString(filters.endDate)}
          setEndDate={date => setFilters(prev => ({ ...prev, endDate: new Date(date + 'T23:59:59.999') }))}
          maxDate={maxDate}
          daysRange={daysRange}
          exceedsDaysRange={exceedsDaysRange}
          setExceedsDaysRange={setExceedsDaysRange}
        />
        <Col md={6}>
          <DropdownBtn
            id="group-data-by-dropdown"
            styleType="filter"
            value={filterGroupByOptions.find(option => option.id === filters.grouping)}
            label="Group data by:"
            placeholder="Select"
            items={filterGroupByOptions}
            onClick={item => {
              handleSelectGrouping(item?.id as string);
            }}
          />
        </Col>
      </Row>
      <hr className="divider-line" />
      <Row className="mb-4">
        <Col md={3}>
          <Card.Subtitle>Filter by:</Card.Subtitle>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <MultiSelectDropdownBtn
            id="dropdown-data"
            allPreSelected
            none
            all
            label="Country"
            placeholder="Select Option"
            items={countryOptions as Item[]}
            onClick={items => handleMultiSelectItemsClick('Country', items)}
            disabled={cBRFilter || countryOptions.length <= 0}
          />
        </Col>
        <Col md={3}>
          <MultiSelectDropdownBtn
            id="dropdown-data"
            allPreSelected
            none
            all
            label="Processor"
            placeholder="Select Option"
            items={processors}
            onClick={items => handleMultiSelectItemsClick('Processor', items)}
            disabled={cBRFilter}
          />
        </Col>
        <Col md={3}>
          <MultiSelectDropdownBtn
            id="dropdown-data"
            allPreSelected
            none
            all
            label="Status"
            placeholder="Select Option"
            items={status}
            onClick={items => handleMultiSelectItemsClick('Status', items)}
            disabled={cBRFilter}
          />
        </Col>
        <Col md={3}>
          <MultiSelectDropdownBtn
            id="dropdown-data"
            allPreSelected
            none
            all
            label="Reason Code"
            placeholder="Select Option"
            items={reasonCodes}
            onClick={items => handleMultiSelectItemsClick('Reason', items)}
            disabled={cBRFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col md={2} className="btn-container">
          <Button disabled={exceedsDaysRange || loading}>UPDATE REPORT</Button>
        </Col>
      </Row>
    </Form>
  );
}

export default WithFeaturesProxy<ExposedProps>(Features.MktFeature_HasMultipleCountry)(
  (props, mktHasMultipleCountry) => {
    return <ReportsFormComponent {...props} mktHasMultipleCountry={mktHasMultipleCountry} />;
  },
);
