import { Button } from '@amway/react-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Applications } from '../../../@types/configuration';
import AsyncAutocompleteComponent from '../../../components/ui/async-autocomplete';
import DateRangeFieldsComponent from '../../../components/ui/date-range-fields';
import DropdownBtn, { Item } from '../../../components/ui/dropdown-btn';
import { ExecutionStatus } from '../../../resources/history-list/history-list-types';
import useProcessors from '../../../resources/processors/processors-hook';
import adminService from '../../../service/admin.service';
import { subtractDays, toISOString } from '../../../utils/date-utils';

export interface CompareEmailsFormFields {
  processorName: string;
  startDate?: string;
  endDate?: string;
  firstExecutionId: string;
  secondExecutionId: string;
}

type Props = {
  processorName?: string | null;
  firstExecutionId?: string | null;
  secondExecutionId?: string | null;
  startDt?: string | null;
  endDt?: string | null;
  onSubmit: (request: CompareEmailsFormFields) => void;
};

export default function CompareEmailsFormComponent({
  processorName,
  firstExecutionId: firstExecutionIdValue,
  secondExecutionId: secondExecutionIdValue,
  startDt,
  endDt,
  onSubmit,
}: Props) {
  const { processors: processorsData, fetchProcessors } = useProcessors();
  const [maxDate] = useState<string>(toISOString(new Date()));
  const [startDate, setStartDate] = useState<string | undefined>(
    !processorName || !firstExecutionIdValue || !secondExecutionIdValue
      ? toISOString(subtractDays(new Date(), 6))
      : undefined,
  );
  const [endDate, setEndDate] = useState<string | undefined>(
    !processorName || !firstExecutionIdValue || !secondExecutionIdValue ? maxDate : undefined,
  );
  const processors: Item[] = useMemo(
    () => (processorsData.data ?? []).map(({ name }) => ({ label: name, id: name })),
    [processorsData.data],
  );
  const [selectedProcessor, setSelectedProcessor] = useState<Item>();
  const [firstExecution, setFirstExecution] = useState<Item | null | undefined>();
  const [secondExecution, setSecondExecution] = useState<Item | null | undefined>();

  useEffect(() => {
    fetchProcessors();
  }, [fetchProcessors]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (selectedProcessor && firstExecution && secondExecution) {
        const request: CompareEmailsFormFields = {
          processorName: selectedProcessor.id as string,
          startDate,
          endDate,
          firstExecutionId: firstExecution.id as string,
          secondExecutionId: secondExecution.id as string,
        };

        onSubmit(request);
      }
    },
    [selectedProcessor, startDate, endDate, firstExecution, secondExecution, onSubmit],
  );

  const submitBtnDisabled = useMemo(
    () => !selectedProcessor || !firstExecution || !secondExecution,
    [selectedProcessor, firstExecution, secondExecution],
  );

  const handleSearch = useCallback(
    (searchText: string, ignoreProcessor = false) =>
      adminService
        .getExecutionHistory({
          searchText,
          processor: ignoreProcessor ? undefined : ([selectedProcessor?.id] as string[]),
          startDate: startDate && endDate ? startDate + 'T00:00:00.000' : undefined,
          endDate: startDate && endDate ? endDate + 'T23:59:59.999' : undefined,
          status: [ExecutionStatus.OK],
          emailPreview: false,
        })
        .then(({ executions }) => executions)
        .then(
          executions =>
            executions.map(execution => ({
              ...execution,
              id: execution.executionId,
              label: execution.executionId,
              secondLabel:
                execution.application === Applications.NA
                  ? Applications.NA.substring(0, 1)
                  : Applications.COMMSHUB.substring(0, 1),
              thirdLabel: execution.endDt ? `${new Date(execution.endDt).toLocaleString()} (GMT)` : undefined,
            })) as Item[],
        ),
    [selectedProcessor, startDate, endDate],
  );

  const handleSelectedFirstExecution = useCallback((selected: Item) => {
    setFirstExecution(selected);
  }, []);

  const handleSelectedSecondExecution = useCallback((selected: Item) => {
    setSecondExecution(selected);
  }, []);

  const performSearchByExecutionId = useCallback(
    (executionId?: string | null, setExecution?: (execution?: Item | null) => void, ignoreProcessor = false) => {
      if (executionId && setExecution) {
        handleSearch(executionId, ignoreProcessor).then(items => {
          setExecution(items.find(({ id }) => id === executionId));
        });
      }
    },
    [handleSearch],
  );

  useEffect(() => {
    if (startDt && endDt) {
      setStartDate(String(startDt.split('T')[0]));
      setEndDate(String(endDt.split('T')[0]));
    }
  }, [startDt, endDt]);

  useEffect(() => {
    performSearchByExecutionId(firstExecutionIdValue, setFirstExecution);
  }, [firstExecutionIdValue, performSearchByExecutionId]);

  useEffect(() => {
    performSearchByExecutionId(secondExecutionIdValue, setSecondExecution, true);
  }, [secondExecutionIdValue, performSearchByExecutionId]);

  useEffect(() => {
    if (processorName && processors?.length > 0) {
      setSelectedProcessor(processors.find(({ id }) => id === processorName));
    }
  }, [processors, processorName]);

  return (
    <Form className="compare-emails-form" onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <DropdownBtn
            id="processor-dropdown"
            placeholder="Processor"
            label="Processor"
            required={true}
            none={true}
            items={processors}
            value={selectedProcessor}
            onClick={setSelectedProcessor as unknown as (item?: Item | null) => void}
          />
        </Col>
        <DateRangeFieldsComponent
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          maxDate={maxDate}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          xxl={3}
        />
      </Row>
      <Row>
        <Col>
          <AsyncAutocompleteComponent
            id="first-execution-id"
            label="First Execution"
            disabled={!selectedProcessor?.id}
            placeholder="Search and select execution"
            items={firstExecution ? [firstExecution] : []}
            search={handleSearch}
            selectedValue={firstExecution}
            selectValue={handleSelectedFirstExecution}
            required
          />
        </Col>
        <Col>
          <AsyncAutocompleteComponent
            id="second-execution-id"
            label="Second Execution"
            disabled={!selectedProcessor?.id}
            placeholder="Search and select execution"
            items={secondExecution ? [secondExecution] : []}
            search={handleSearch}
            selectedValue={secondExecution}
            selectValue={handleSelectedSecondExecution}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end">
          <Button disabled={submitBtnDisabled}>COMPARE</Button>
        </Col>
      </Row>
    </Form>
  );
}
